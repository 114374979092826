@import '../../../styles/colors.scss';

.containerL .content {
    .modal-footer {
        justify-content: center;
    }

    .estimate,
    .release,
    .balance,
    .simulation,
    .concluded {
        h2 {
            text-align: center;
        }

        h3 {
            text-align: center;
            padding-bottom: 15px;
        }

        h4 {
            text-align: center;
        }
    }

    .customer-data {
        h3 {
            text-align: center;
        }

        form {
            h3::before,
            h3::after {
                border-bottom: 1px solid $base-color;
            }
        }
    }

    .concluded .container div {
        text-align: center;
    }
}
