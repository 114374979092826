@import '../../styles/colors.scss';

.email-confirmation {
    padding-top: 7rem;
    padding-bottom: 7rem;
    color: #000;
    text-align: center;

    .icon-email {
        font-size: 10rem;
        color: $base-color;
    }

    h1 {
        margin-top: 1rem;
        margin-bottom: 2rem;
        color: $base-color;
    }

    p {
        line-height: 1.8rem;
    }
}
