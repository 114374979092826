@import '../../../styles/colors.scss';

section.simulateNow {
    background-color: $base-color;
    padding: 3em 0;

    h3 {
        font-size: 1.7em;
        font-weight: 500;
        color: $secondary-color;
        text-align: left;
    }

    @media screen and (max-width: 768px) {
        h3 {
            font-size: 1.3em;
            padding-bottom: 1em;
            text-align: center;
        }
    }
}
