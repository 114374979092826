@import '../../../../../../../styles/colors.scss';

.form-complementary-data {
    h3 {
        display: flex;
        align-items: center;
        text-align: center;
        padding: 1rem 0;
    }

    h3::before,
    h3::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #fff;
    }

    h3::before {
        margin-right: .8rem;
    }

    h3::after {
        margin-left: .8rem;
    }

    .form-group {
        text-align: left;
    }
}

.accordion {
  color: #000;

  .btn-link {
    text-decoration: none;
  }

  p {
    text-align: left;
    color: #000;
  }

    .react-html5-camera-photo>img,
    .react-html5-camera-photo>video {
        width: 100% !important;
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
