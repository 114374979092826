.col-md-12 {
  border: 1px solid #2f4e90;
  padding: 0.8rem;
}

.table {
  margin: 0;

  td {
    border-top: 1px solid #2f4e90;
    height: 30px;
    vertical-align: middle;
    min-width: 100px;
  }

  .edit-icon {
    cursor: pointer;
  }

  .resolve-issue-icon {
    cursor: pointer;
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }

  .formalization-link-icon {
    cursor: pointer;
    margin-left: 5px;
  }

  .details-link-icon {
    cursor: pointer;

    margin-right: 5px;
  }

}

.proposals-form-container {
  display: flex;
  margin-bottom: 20px;

  input {
    border: 2px solid #2f4e90;
    border-right: none;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #000;
    height: 30px;

  }

  input:focus {
    color: #2f4e90;
  }

  input::placeholder {
    padding-left: 0.2rem;
  }

  button {
    width: 30px;
    height: 30px;
    border: 1px solid #2f4e90;
    background: #2f4e90;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;

    img {
      width: 20px;
      height: 20px;
      margin-top: 4px;
    }
  }
}

.proposal-bank-partner-choice {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  button {
    margin: 0 0.5rem;
    padding: 0.2rem;
    cursor: pointer;
    border: 1px solid #2f4e90;
    background: #2f4e90;
    text-align: center;
    color: #fff;
    border-radius: 5px;
  }

  .block-cursor {
    background: gray;
    border: 1px solid #3f3f3f;
    cursor: default;
  }
}

.excel-container {
  margin-top: 0.6rem;

  .date-container {
    display: flex;

    .dates-info {
      max-width: 120px;
      margin-right: 20px;

      input {
        margin: 5px 0 10px 0;
        cursor: pointer;
      }
    }
  }

  .excel-button-container {
    margin-top: 10px;
  }

  button {
    cursor: pointer;
    padding: 0.2rem;
    border: 1px solid #2f4e90;
    background: #2f4e90;
    color: #fff;
    border-radius: 5px;
  }
}

.hide {
  display: none !important;
}

.no-scroll {
  overflow: hidden;
}

#status-modal,
#pending-issue-modal,
#formalization-link-modal {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0%;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  background-color: #070707e3;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.modal-default {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0%;
  margin: 0 auto;
  width: 100vw;
  height: auto;
  z-index: 9999;
  display: flex;
  background-color: #070707e3;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.status-modal-container,
.modal-default {
  display: flex;
  flex-direction: column;
  max-width: 500px;

  background-color: #3a6491;
  border: 5px solid #fff;
  color: #fff;
  margin: 0 auto;
  border-radius: 0.5rem;

  h2 {
    font-size: 1.4rem;
    margin: 10px 0;
    align-self: center;
    font-weight: 400;
  }

  .status-change-container,
  .modal-change-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    select {
      margin: 10px 0;
      max-width: 300px;
    }
  }

  .update-status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 30px 0;
  }

  .update-status-button {
    cursor: pointer;
    padding: 0.2rem;
    border: 1px solid #2f4e90;
    background: #fff;
    color: #2f4e90;
    border-radius: 5px;
    width: 150px;
    height: 30px;
  }

  .error-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

#proposal-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0%;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  background-color: rgba(58, 100, 144, 0.67);
  flex-direction: column;
  justify-content: center;
  text-align: center;



}


.proposal-details-modal-container {
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #f5f5f5;
  border: 5px solid rgba(58, 100, 144);;
  color: #2f4e90;
  margin: 0 auto;
  border-radius: 0.5rem;


  h2 {
    font-size: 1.4rem;
    margin: auto;
    align-self: center;
    font-weight: 400;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1.3rem;
    text-align: left;
    font-weight: 400;
    background-color: #3a6491;
    color: #fff;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px;
  }

  .proposal-details-modal-info-container .col-md-4 .phead  {

    font-weight: bold;
    text-align: left;
    margin: 5px;

  }

  .proposal-details-modal-info-container .col-md-4 .pdata {

    text-align: left;
    margin: 5px;

  }



  .status-change-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    select {
      margin: 10px 0;
      max-width: 00px;
    }
  }

  .update-status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 30px 0;

    .update-status-button {
      cursor: pointer;
      padding: 0.2rem;
      border: 1px solid #2f4e90;
      background: #fff;
      color: #2f4e90;
      border-radius: 5px;
      width: 150px;
      height: 30px;
    }
  }

  .error-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 200px;
  }

  .proposal-details-modal-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;

    h2 {
      font-size: 18px;
      margin: 10px 0;
      align-self: center;
      font-weight: bold;

    }


  }

}

.iclose-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;


  button {
    width: 20px;
    height: 20px;
    margin: 5px;
    border: none;
    padding-top: 1px;
    cursor: pointer;

    p {
      font-weight: 900;
      color: #2f4e90;
      transition: ease-in 0.2s;
    }

    p:hover {
      color: #ffa03b;
    }
  }
}

.pending-issue-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  padding: 10px;
  background-color: #3a6491;
  border: 5px solid #fff;
  color: #fff;
  margin: 0 auto;
  border-radius: 0.5rem;

  .pending-issue-change-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 24px;
      margin: 10px 0 40px 0;
      align-self: center;
      font-weight: 400;
    }

    label {
      font-style: italic;
      font-weight: bold;
      color: #ff4949;
      margin-bottom: 10px;
    }

    .phone-input {
      padding: 5px;
      border-radius: 5px;
      border: none;
      color: #3a6491;
    }

    input {
      margin: 2px 10px 30px 10px;
    }

    input[type=file]::file-selector-button {
      border: none;
      padding: 5px;
      border-radius: 5px;
      background: #fff;
      color: #2f4e90;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      margin: 10px;
      cursor: pointer;
    }
  }

  .update-status-container {
    margin: 10px;

    button {
      cursor: pointer;
      padding: 8px;
      background: #fff;
      color: #2f4e90;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  .error-container {
    display: flex;
    align-self: center;
    align-items: center;

    .alert {
      font-size: 12px;
      padding: 5px;
      margin: 10px;
    }
  }
}

.close-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 16px;

  button {
    width: 20px;
    height: 20px;
    margin: 5px;
    border: none;
    padding-top: 1px;
    cursor: pointer;

    p {
      font-weight: 900;
      color: #fff;
      transition: ease-in 0.2s;
    }

    p:hover {
      color: #ffa03b;
    }
  }
}

.formalization-link-modal-container {
  display: flex;
  flex-direction: column;
  background-color: #3a6491;
  border: 5px solid #fff;
  color: #fff;
  margin: 0 auto;
  border-radius: 0.5rem;

  .formalization-link-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 18px;
      margin: 10px 0;
      align-self: center;
      font-weight: 400;
    }

    input {
      width: 500px;
      font-size: 12px;
      margin: 20px 5px;
      border: none;
      padding: 5px;
      border-radius: 5px;
      background: #fff;
      color: #2f4e90;
      font-weight: bold;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
}

@media screen and (max-width: 570px) {
  .table {
    font-size: 0.8rem;

    th {
      text-align: center;
    }

    td {
      text-align: center;
    }
  }

  .proposals-form-container {
    input {
      width: 150px;
      height: 1.5rem;
    }

    input::placeholder {
      font-size: 0.8rem;
    }

    button {
      width: 24px;
      height: 24px;

      img {
        width: 16px;
        height: 16px;
        margin-top: 0;
      }
    }

    img {
      width: 16px;
      height: 16px;
      margin-top: 0;
    }
  }

  .pagination-container {

    button,
    .page-number-container {
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .formalization-link-modal-container {
    margin: 0 10px;

    .formalization-link-info-container {
      h2 {
        font-size: 14px;
      }

      input {
        width: 400px;
        font-size: 9px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .table {
    font-size: 0.6rem;
  }

  .formalization-link-modal-container {
    margin: 0 5px;

    .formalization-link-info-container {
      h2 {
        font-size: 12px;
      }

      input {
        width: 330px;
        font-size: 8px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .table {
    font-size: 0.5rem;
  }
}

.file-input-label {
  background-color: #fff;
  color: #000;
  font-size: .9em;
  padding: 10px 15px;
  border: 1px solid black;
  border-radius: 4%;
  cursor: pointer;
  margin: 0;
}
