a{
    text-decoration: none;
    color: black;
}

ul {
    list-style: none;
}

img {
    max-width: 100%;
}

body {
    background-color: #3A6491;
}