.fgts-pay-section {
  color: #000;
  background-color: #0c8591;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  justify-content: center;

  .fgts-pay-container {
    width: 80%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #2E5074;
    border-radius: 10px;

    h3 {
      margin: 32px 10px;
      color: #fff;
      font-size: 30px;
    }

    h4 {
      margin: 5px 0;
      font-weight: 400;

      span {
        font-weight: 700;
      }
    }

    .first-page-text {
      margin-top: 20px;
      margin-bottom: 40px;
      color: #fff;
    }

    .purchase-info-container {
      margin-top: 20px;

      label {
        margin-right: 0;
        color: #fff;
      }

      h3 {
        margin: 10px;
        font-size: 24px;
      }
    }

    .main-info-button {
      margin: 10px;
      padding: 5px;
      font-weight: 700;
      font-size: 16px;
      width: 300px;
      background-color: #fff;
      border: none;
      border-radius: 20px;
      color: #2E5074;
      cursor: pointer;
      align-self: center;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .hiring-term-main-page-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        max-width: 600px;
        color: #fff;
        font-size: 24px;
        margin-top: 40px;
      }

      .hiring-term-link-container {
        display: flex;
        flex-direction: column;
        height: 300px;
        justify-content: center;
        align-items: center;

        a {
          font-weight: 600;
          border-bottom: 1px solid #3a6491;
          padding-bottom: 2px;
          color: #fff;
        }

        .hiring-term-checkbox-container {
          margin-top: 20px;
          display: flex;
          align-items: center;

          input {
            margin-right: 5px;
            cursor: pointer;
          }

          label {
            cursor: pointer;
            font-weight: 500;
            color: #fff;
          }
        }

      }

      button {
        padding: 10px;
        margin: 20px;
        font-weight: 700;
        font-size: 14px;
        min-width: 150px;
        background-color: #fff;
        border: none;
        border-radius: 20px;
        color: #2E5074;
        cursor: pointer;
        align-self: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }

      .alert-text-center {
        font-size: 14px;
        text-align: center;
      }

    }

    #intro-page-alert {
      margin-top: 50px;
    }

    .hide {
      display: none !important;
    }

    .no-scroll {
      overflow: hidden;
    }

    #fgts-pay {
      position: absolute !important;
      border-radius: 10px;
      top: 0;
      left: 0;
      right: 0%;
      margin: 0 auto;
      width: 100vw;
      height: 100vh;
      z-index: 9999;
      display: flex;
      background-color: #070707bd;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .hiring-term-container {
        max-width: 500px;
        border-radius: 10px;
        background-color: #f0f8ff;

        .close-button-container {
          button {
            p {
              color: #000;
              font-weight: 500;
              font-size: 16px;
            }
          }
        }

        h2 {
          color: #3a6491;
          font-weight: 600;
          font-size: 28px;
          margin: 20px 0;
          align-self: center;
        }

        .hiring-term-text {
          margin: 5px;
        }

        .hiring-term-buttons-container {
          margin: 10px;

          button {
            padding: 10px;
            margin: 10px;
            font-weight: 700;
            font-size: 14px;
            min-width: 150px;
            background-color: #3a6491;
            border: none;
            border-radius: 10px;
            color: #f0f8ff;
            cursor: pointer;
            align-self: center;
          }
        }
      }
    }

    .fgtspay-modal-container {
      display: flex;
      flex-direction: column;
      width: 500px;
      height: 500px;
      background-color: #2E5074;
      border: 2px solid #fff;
      color: #fff;
      margin: 0 auto;
      border-radius: 0.5rem;

      h2 {
        color: #fff;
      }
    }

    .fgts-info-container {
      display: flex;
      flex-direction: column;

      p {
        margin: 20px;
        line-height: 25px;
      }
    }

    .close-button-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 16px;

      button {
        width: 20px;
        height: 20px;
        margin: 5px;
        border: none;
        padding-top: 1px;
        cursor: pointer;

        p {
          font-weight: 900;
          color: #fff;
          transition: ease-in 0.2s;
        }

        p:hover {
          color: #0c8591;
        }
      }
    }

    .hiring-steps-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin-top: 32px;
      }

      .hiring-steps-text {
        color: #fff;
        max-width: 700px;
        text-align: center;
        margin: 10px 10px 0 10px;
      }

      .circles-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        .circle {
          display: flex;
          max-width: 240px;
          flex-direction: column;
          align-items: center;
          margin: 0 20px;

          .circle-number {
            width: 100px;
            height: 100px;
            color: #fff;
            border: 2px dashed #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
          }

          p {
            margin-top: 20px;
            margin-bottom: 20px;
            color: #fff;
            text-align: center;
            font-size: 14px;
            height: 80px;
          }

          a {
            display: flex;
            align-items: center;
            font-size: 12px;
            max-width: 200px;
            min-height: 80px;
            background-color: #fff;
            color: #2E5074;
            padding: 10px;
            border: none;
            cursor: pointer;
            border-radius: 20px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }

          button {
            background-color: #fff;
            color: #2E5074;
            cursor: pointer;
            padding: 10px;
            font-size: 12px;
            border-radius: 20px;
            min-height: 80px;
            max-width: 200px;
            border: none;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }
        }

        .arrow {
          font-size: 32px;
          margin: 0 20px;
          color: #ffffff;
          height: 60px;
        }
      }

      .hiring-back-button {
        margin-top: 80px;
        background-color: #fff;
        font-weight: bold;
        color: #2E5074;
        cursor: pointer;
        width: 200px;
        padding: 6px 22px;
        border: none;
        height: 36px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }

    .simulation-fields-title {
      color: #fff;
      margin: 16px 10px;
    }

    .simulation-form {
      max-width: 800px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .fields-row {
        margin: 0 10px;

        .form-group {
          label {
            color: #fff;
          }

          input {
            margin-top: 5px;
          }
        }
      }

      button {
        margin: 32px 10px;
        width: 200px;
        background-color: #3a6491;
        border: none;
      }
    }

    .parcels-container {
      margin: 80px 10px;
      border-radius: 20px;

      .table-responsive {
        border-radius: 10px;

        .table {

          thead {
            tr {
              th {
                font-size: 14px;
                color: #2E5074;
                vertical-align: middle;
              }
            }
          }


          tbody {
            tr {
              th {
                font-size: 16px;
                font-weight: bold;
                color: #2E5074;
                vertical-align: middle;
              }
            }
          }
        }
      }

      button {
        margin-top: 100px;
        background-color: #fff;
        color: #2E5074;
        cursor: pointer;
        padding: 10px;
        border: none;
        border-radius: 20px;
        font-weight: bold;
        width: 125px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }

    .container {
      .form-complementary-data {
        h4 {
          color: #fff;
          font-size: 20px;
        }

        .pix-container {
          display: flex;
          flex-direction: column;
          height: 250px;
          align-items: center;
          justify-content: center;

          .pix-radio-outter-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .pix-radio-container {
              margin: 5px;

              label {
                margin-left: 5px;
                color: #fff;
                font-size: 16px;
              }
            }
          }

          .pix-input-container {
            margin-top: 20px;

            input {
              border-radius: 10px;
              padding: 5px 10px;
              width: 300px;
              font-size: 14px;
              border: none;
              outline: none;
            }
          }
        }

        .form-row {
          .form-group {
            margin-bottom: 10px;

            label {
              color: #fff;

            }

            input {
              margin-top: 2px;
            }
          }
        }
      }
    }


    .data-fields {
      margin-top: 50px;
      max-height: 400px;
    }

    .data-fields-buttons-fgts-pay-page-6 {
      button {
        background-color: #fff;
        border: none;
        color: #2E5074;
        padding: 5px;
        width: 125px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }

    .form-sdk-data {
      h4 {
        margin-top: 24px;
      }

      #sdk-finalization-page {
        display: flex;
        height: 500px;
        align-items: center;
        justify-content: center;

        .alert {
          margin: 0;
        }
      }

      .form-sdk-row {
        display: flex;
        flex-direction: column;

        .sdk {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 50px;

          h3 {
            font-size: 24px;
          }

          h4 {
            margin-bottom: 20px;
            color: #fff;
          }

          button {
            margin: 0 10px;
            font-weight: 700;
            font-size: 16px;
            padding: 10px;
            background-color: #fff;
            border: none;
            border-radius: 10px;
            color: #2E5074;
            cursor: pointer;
            align-self: center;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }

          .sdk-radio-container {
            display: flex;
            justify-content: center;
            align-items: center;

            label {
              display: flex;
              margin-right: 20px;
              color: #fff;

              input {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    .data-fields-buttons {
      display: flex;
      margin-top: 20px;

      button {
        background-color: #fff;
        border: none;
        color: #2E5074;
        min-width: 125px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }

    .concluded-message-container {
      display: flex;
      height: 550px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 30px;
      }

      h4 {
        max-width: 600px;
        margin: 20px;
        font-size: 24px;
        color: #fff;
      }

      img {
        margin-top: 20px;
        height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
}

.button-container {
  margin-top: 20px;
  width: 500px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .simulation-button {
    margin: 10px;
    padding: 5px;
    font-weight: 700;
    font-size: 16px;
    width: 300px;
    background-color: #fff;
    border: none;
    border-radius: 20px;
    color: #2E5074;
    cursor: pointer;
    align-self: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

.parcels-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loan-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    background-color: #fdfdfe;
    border-radius: 10px;
    padding: 10px 20px;
    color: #2E5074;
    font-weight: bold;

    ul {
      li {
        border-bottom: 1px solid #d9d9d8;
        padding: 10px 0;
        font-size: 20px;

        span {
          color: #CA995D;
        }
      }
    }

    .info-simulation {
      text-align: left;
      line-height: 12px;
      margin-top: 20px;
      margin-bottom: 0;
      border-bottom: none;
      font-size: 16px;

      span {
        color: #CA995D;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .fgts-pay-section {
    .fgts-pay-container {
      label {
        font-size: 14px;
      }

      .form-control {
        font-size: 12px;
      }

      button,
      .main-info-button {
        font-size: 12px;
        min-height: 36px;
      }
    }
  }
}

@media screen and (max-width: 951px) {
  .fgts-pay-section {
    .fgts-pay-container {
      .hiring-steps-container {

        h3 {
          font-size: 20px;
        }

        .hiring-steps-text {
          font-size: 14px;
        }

        .circles-container {


          .circle {
            margin: 0 10px;

            .circle-number {
              width: 80px;
              height: 80px;
              font-size: 24px;
            }

            p {
              font-size: 12px;
            }

            a {
              font-size: 10px;
            }

            button {
              font-size: 10px;
            }
          }

          .arrow {
            font-size: 24px;
            height: 80px;
          }
        }

        // .hiring-back-button {
        //  font-size: 12px;
        //  max-width: 160px;
        // }
      }
    }
  }
}

@media screen and (max-width: 867px) {

  .fgts-pay-section {
    .fgts-pay-container {
      .parcels-container {
        .table-responsive {
          border-radius: 10px;

          .table {

            thead {
              tr {
                th {
                  font-size: 10px;
                }
              }
            }


            tbody {
              tr {
                th {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .fgts-pay-section {


    .fgts-pay-container {

      .hiring-steps-container {

        h3 {
          font-size: 16px;
        }

        .hiring-steps-text {
          font-size: 12px;
        }

        .circles-container {


          .circle {
            margin: 0 10px;

            .circle-number {
              width: 60px;
              height: 60px;
              font-size: 18px;
            }

            p {
              font-size: 10px;
            }

            a {
              font-size: 8px;
              height: 100px;
            }

            button {
              font-size: 8px;
              height: 100px;
            }
          }

          .arrow {
            font-size: 20px;
          }
        }

        // .hiring-back-button {
        //  font-size: 12px;
        //  max-width: 160px;
        // }
      }

      .modal-footer {
        margin-top: 320px;
      }

      .alert-text-center {
        font-size: 12px;
        max-width: 300px;
      }

      .alert-text-center-fgts-pay-page-6 {
        font-size: 12px;
        margin-top: 240px;
        margin-bottom: -130px;
        max-width: 300px;
      }

      .data-fields {
        margin-top: 0;
        padding-top: 0;

        h3 {
          margin-top: 20px;
          margin-bottom: 10px;
          font-size: 16px;
        }

        .container {
          .form-complementary-data {

            h4 {
              margin: 40px 5px 0 5px;
              font-size: 15px;
            }

            .pix-container {
              margin-bottom: -100px;

              .pix-radio-outter-container {


                .pix-radio-container {
                  label {
                    font-size: 14px;
                  }
                }
              }

              .pix-input-container {
                input {
                  width: 260px;
                  font-size: 12px;
                }
              }
            }

            .form-row {
              .form-group {
                label {
                  font-size: 12px;
                }

                input {
                  font-size: 10px;
                  height: 25px;
                }

                select {
                  font-size: 10px;
                  height: 25px;
                }

                .invalid-feedback {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }

      .data-fields-buttons-fgts-pay-page-6 {
        margin-top: 120px;
      }
    }
  }
}

@media screen and (max-width: 686px) {
  .fgts-pay-section {
    .fgts-pay-container {
      margin: 10px;

      h3 {
        font-size: 15px;
      }

      .first-page-text {
        font-size: 13px;
        margin: 30px 10px;
      }

      .purchase-info-container {
        label {
          font-size: 12px;
        }

        h3 {
          font-size: 16px;
        }
      }

      .simulation-button {
        font-size: 12px;
        width: 200px;
      }

      .main-info-button {
        font-size: 12px;
        width: 200px;
      }

      .estimate {
        .simulation-form {


          .fields-row {
            margin: 0 10px;

            .form-group {
              display: flex;
              flex-direction: column;
              align-items: center;

              input {
                max-width: 300px;
              }
            }
          }
        }
      }

      .parcels-container {
        .table-responsive {
          border-radius: 10px;

          .table {

            thead {
              tr {
                th {
                  font-size: 8px;
                }
              }
            }


            tbody {
              tr {
                th {
                  font-size: 10px;
                }
              }
            }
          }
        }

        button {
          font-size: 10px;
          max-width: 80px;
        }
      }

      .hiring-term-main-page-container {

        h3 {
          font-size: 16px;
        }

        .hiring-term-link-container {

          a {
            font-size: 14px;
          }

          .hiring-term-checkbox-container {

            label {
              font-size: 12px;
            }
          }

        }

        button {
          font-size: 12px;
          max-width: 80px;
        }

        .alert-text-center {
          font-size: 12px;
        }
      }

      .data-fields-buttons {
        display: flex;
        margin-top: 10px;

        button {
          background-color: #fff;
          font-size: 12px;
          border: none;
          color: #2E5074;
          padding: 5px;
          width: 80px;
        }
      }

      .concluded-message-container {
        h3 {
          font-size: 18px;
          margin: 20px;
        }

        h4 {
          font-size: 16px;
        }

        div {
          img {
            width: 160px;
          }
        }
      }

      .form-sdk-data {
        .form-sdk-row {
          .sdk {
            h3 {
              font-size: 16px;
            }

            h4 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .parcels-container {

    .loan-info {


      ul {
        li {
          font-size: 16px;
        }
      }

      .info-simulation {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .fgts-pay-section {

    .fgts-pay-container {

      .container {
        .form-complementary-data {

          .pix-container {
            margin-bottom: -100px;

            .pix-radio-outter-container {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;

              .pix-radio-container {
                label {
                  font-size: 14px;
                }
              }
            }

            .pix-input-container {
              input {
                width: 260px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .data-fields-buttons-fgts-pay-page-6 {
      margin-top: 120px;
    }
  }
}


@media screen and (max-width: 551px) {
  .fgts-pay-section {
    padding-bottom: 100px;

    .fgts-pay-container {
      max-width: 400px;

      #fgts-pay {
        h2 {
          font-size: 15px;
          margin: 32px 10px;
        }

        p {
          font-size: 14px;
        }
      }

      .fgtspay-modal-container {
        width: 90%;
      }

      .hiring-steps-container {

        h3 {
          font-size: 16px;
        }

        .hiring-steps-text {
          font-size: 12px;
        }

        .circles-container {


          .circle {
            margin: 0 10px;

            .circle-number {
              width: 50px;
              height: 50px;
              font-size: 16px;
            }

            p {
              font-size: 8px;
              height: 100px;
            }

            a {
              font-size: 7px;
              height: 110px;
              width: 70px;
            }

            button {
              font-size: 7px;
              height: 110px;
              width: 70px;
            }
          }

          .arrow {
            margin: 0 2px;
          }
        }

        // .hiring-back-button {
        //  font-size: 12px;
        //  max-width: 160px;
        // }
      }

      .estimate {
        .simulation-form {


          .fields-row {
            margin: 0 10px;

            .form-group {
              display: flex;
              flex-direction: column;
              align-items: center;

              input {
                max-width: 220px;
                font-size: 10px;
              }
            }
          }
        }
      }

      .alert-text-center-fgts-pay-page-6 {
        max-width: 200px;
      }

      .form-sdk-data {
        .form-sdk-row {
          .sdk {
            h3 {
              font-size: 14px;
            }

            h4 {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 501px) {
  .fgts-pay-section {
    .fgts-pay-container {
      .simulation-button {
        font-size: 10px;
        width: 150px;
      }

      .main-info-button {
        font-size: 10px;
        width: 150px;
      }

      .alert-text-center {
        font-size: 12px;
        max-width: 200px;
      }

      .hiring-steps-container {
        .hiring-steps-text {
          font-size: 10px;
        }

        img {
          margin-top: 25px;
          width: 270px;
        }
      }

      .hiring-term-main-page-container {

        h3 {
          font-size: 14px;
        }

        .hiring-term-link-container {

          a {
            font-size: 12px;
          }

        }

        .alert-text-center {
          font-size: 10px;
          padding: 10px;
        }

      }

      .data-fields {

        .container {
          .form-complementary-data {

            .pix-container {

              .pix-radio-outter-container {

                .pix-radio-container {
                  label {
                    font-size: 12px;
                  }
                }
              }

              .pix-input-container {
                input {
                  width: 220px;
                  font-size: 10px;
                }
              }
            }
          }
        }
      }

      .data-fields-buttons-fgts-pay-page-6 {
        button {
          width: 110px;
        }
      }
    }
  }

  .parcels-container {

    .loan-info {


      ul {
        li {
          font-size: 14px;
        }
      }

      .info-simulation {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .fgts-pay-section {
    .fgts-pay-container {
      .hiring-steps-container {
        img {
          width: 260px;
        }
      }

      .simulation-button {
        margin: 10px 5px;
        font-size: 9px;

        .main-info-button {
          font-size: 10px;
        }
      }
    }
  }
}
