@import '../../../../../styles/colors.scss';
@import '../../../../../styles/table.scss';

.modal-simulator .balance,
.containerL .content .balance {
  .container {
    .row {
      .col-md-12 {
        border: none;
      }
    }
  }
  .form-group {
    text-align: left;

    label {
      display: flex;
      padding-bottom: 0.5rem;
    }
  }

  // .row {
  //   .col-md-12 {
  //     border: none;
  //   }
  // }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    text-align: left;
  }

  table,
  th,
  td {
    border: none;
  }

  table thead {
    background-color: $base-color;
    color: white;
  }

  table tbody .unchecked {
    color: #e74c3c;
    text-decoration: line-through;
  }
}

.modal-simulator.partner-page-simulator .balance {
  table {
    border: #fff solid 1px;
  }

  table thead {
    background-color: #fff;
    color: #000;
  }

  table tbody {
    background-color: #717171;
    color: #fff;

    tr {
      border: #fff solid 1px;
    }

    .unchecked {
      color: #000;
      text-decoration: line-through;
    }
  }
}
