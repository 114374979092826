@import '../../../styles/colors.scss';

section.new-about {
  background-color: #fff;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 3rem 0;

  p {
    color: $base-color;
  }

  p.subtitle {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.7em;
    padding-bottom: 4em;
  }

  table {
    border: none;
    border-spacing: 0px;
    font-size: 0.8em;
    text-align: left;
    margin-bottom: 1em;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
    width: 100%;
    position: relative;
    z-index: 1;
    background-color: #fff;
  }

  table thead {
    background-color: $primary-fgts-color;
  }

  table thead th {
    padding: 10px;
    font-weight: 700;
  }

  table tbody {
    color: $base-color;
  }

  table tbody td {
    padding: 10px;
    font-weight: 400;
    margin-right: 5px;
    line-height: 1.5em;
  }

  p.info-table {
    font-size: 0.8em;
    font-weight: 400;
    text-align: left;
    line-height: 1.5em;
  }

  ul {
    color: $base-color;
  }

  ul li {
    display: flex;
    align-items: flex-start;
    list-style: none;
    font-weight: 400;
    font-size: 1.3em;
    margin-bottom: 1.3em;

    span {
      padding-right: 5px;
    }

    .icon {
      color: $primary-fgts-color;
      font-size: 1.3em;
    }
  }

  .partner-fgts {
    margin-top: 9rem;

    h5 {
      color: #717171;
    }

    h2 {
      color: #000;
    }

    p.subtitle {
      color: #717171;
    }

    .requeriments-fgts {
      .col-md-12 {
        border: none;
      }
      h4 {
        font-size: 1.9em;
        font-weight: 900;
        text-align: left;
        padding-bottom: 0.5em;
        color: #000;
        text-align: center;
        margin-bottom: 2rem;
      }

      ul li {
        justify-content: center;
        color: #717171;

        .icon {
          color: #000;
        }
      }
    }
  }

  .group-shape {
    display: flex;
    margin-left: -35px;
    justify-content: left;

    img {
      width: 100%;
    }

    span {
      position: absolute;
      padding: 2em 0 2em 2em;
      width: 85%;
      text-align: left;

      h4 {
        font-size: 1.9em;
        font-weight: 900;
        text-align: left;
        padding-bottom: 0.5em;
      }
    }
  }

  .requirements-fgts {
    padding-top: 5em;
    text-align: left;
    margin: 0;
    align-items: end;

    .group-people-shape {
      display: flex;
      align-items: flex-end;
    }

    .people {
      position: absolute;
      padding-left: 3em;
      height: 120%;
    }

    .group-shape {
      display: flex;
      margin-left: -15px;
      justify-content: left;
      align-items: center;

      img {
        width: 100%;
      }

      span {
        position: absolute;
        padding: 3em 0 3em 15em;
        width: 85%;

        h4 {
          font-size: 2em;
          font-weight: 900;
          text-align: left;
          max-width: 9em;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .group-shape {
        span {
          padding: 3em 0 3em 10em;

          h4 {
            font-size: 1.3em;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    p.subtitle {
      padding-bottom: 2em;
    }

    .info-table {
      padding-bottom: 2em;
    }

    ul {
      padding-top: 2em;
    }

    .group-shape {
      span {
        h4 {
          font-size: 1.3em;
        }
      }
    }
  }
}

@media screen and (max-height: 690px) {
  section.new-about {
    .partner-fgts {
      margin-top: 12rem;
    }
  }
}

@media screen and (max-height: 615px) {
  section.new-about {
    .partner-fgts {
      margin-top: 14rem;
    }
  }
}

@media screen and (max-height: 560px) {
  section.new-about {
    .partner-fgts {
      margin-top: 16rem;
    }
  }
}
