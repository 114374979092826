@import '../../../../styles/colors.scss';

.admin.simulation {
    table {
        border-spacing: 0;
        border-collapse: collapse;
        text-align: center;
    }

    table, th, td {
        border: none;
    }

    table thead {
        background-color: $base-color;
        color: white;
    }

    table tbody .unchecked {
        color: #e74c3c;
        text-decoration: line-through;
    }

    // PRICE TABLE
    .pricingTable10 {
        margin-bottom: 15px;
        text-align: center;
    }

    .pricingTable10 .pricingTable-header {
        padding: 30px 0;
        background: $base-color;
        position: relative;
        transition: all 0.3s ease 0s;
    }

    // .pricingTable10:hover .pricingTable-header {
    //   background: $base-color;
    // }

    .pricingTable10 .pricingTable-header:after,
    .pricingTable10 .pricingTable-header:before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #d9d9d8;
        position: absolute;
        bottom: 12px;
    }

    .pricingTable10 .pricingTable-header:before {
        left: 40px;
    }

    .pricingTable10 .pricingTable-header:after {
        right: 40px;
    }

    .pricingTable10 .heading {
        font-size: 20px;
        color: #fff;
        text-transform: uppercase;
        margin-top: 0;
    }

    .pricingTable10 .price-value {
        display: inline-block;
        position: relative;
        font-size: 40px;
        font-weight: 700;
        color: $secondary-color;
        // transition: all .3s ease 0s;
    }

    // .pricingTable10:hover .price-value {
    //   color:#fff
    // }

    .pricingTable10 .currency {
        font-size: 22px;
        font-weight: 700;
        position: relative;
        top: -20px;
    }

    .pricingTable10 .cents {
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
    }

    .pricingTable10 .info-simulation {
        text-align: left;
        line-height: 12px;
        font-size: 70% !important;
        font-weight: normal !important;
    }

    .pricingTable10 .pricing-content {
        padding-top: 50px;
        padding-bottom: 25px;
        background: #fff;
        position: relative;

        span {
            color: $secondary-color;
            font-weight: 800;
        }
    }

    .pricingTable10 .pricing-content:after,
    .pricingTable10 .pricing-content:before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #7c7c7c;
        position: absolute;
        top: 12px;
    }

    .pricingTable10 .pricing-content:before {
        left: 40px;
    }

    .pricingTable10 .pricing-content:after {
        right: 40px;
    }

    .pricingTable10 .pricing-content ul {
        padding: 0 15px;
        margin: 0;
        list-style: none;
    }

    .pricingTable10 .pricing-content ul:after,
    .pricingTable10 .pricing-content ul:before {
        content: '';
        width: 8px;
        height: 46px;
        border-radius: 3px;
        background: linear-gradient(to bottom, #818282 50%, #727373 50%);
        position: absolute;
        top: -22px;
        box-shadow: 0 0 5px #707070;
        // transition: all .3s ease 0s;
    }

    // .pricingTable10:hover .pricing-content ul:after,
    // .pricingTable10:hover .pricing-content ul:before {
    //   background: linear-gradient(to bottom, #11b8c7 50%, $base-color-light 50%);
    // }

    .pricingTable10 .pricing-content ul:before {
        left: 44px;
    }

    .pricingTable10 .pricing-content ul:after {
        right: 44px;
    }

    .pricingTable10 .pricing-content ul li {
        font-size: 15px;
        font-weight: 500;
        color: #777473;
        padding: 10px 0;
        border-bottom: 1px solid #d9d9d8;
    }

    .pricingTable10 .pricing-content ul li:last-child {
        border-bottom: none;
    }

    .loan-info-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px;

      h3 {
        font-size: 16px;
        margin-bottom: 10px;
        padding: 10px;
        font-weight: 400;
      }

      .copy-text-button {
        text-align: center;
        font-size: 12px;
        background-color: #3a6491;
        color: #fff;
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;
        max-width: 200px;
        border: none;
        margin-bottom: 10px;
      }
    }



    @media screen and (max-width: 990px) {
        .pricingTable10 {
            margin-bottom: 25px;
        }
    }
}
