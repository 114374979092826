.hide {
  display: none !important;
}

.no-scroll {
  overflow: hidden;
}

#modal-whatsapp {
  position: absolute !important;
  border-radius: 10px;
  top: 0;
  left: 0;
  right: 0%;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  background-color: #070707bd;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: 1.4rem;
    margin-top: 2rem;
    color: #282c34;
    width: 350px;
    align-self: center;
  }
}

.whatsapp-modal-container {
  display: flex;
  flex-direction: column;
  width: 500px;
  background: url('../../assets/other-images/modal-background.jpg');
  border: 5px solid #fff;
  margin: 0 auto;
  border-radius: 0.5rem;
}

.close-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;

  button {
    width: 20px;
    height: 20px;
    margin: 0.2rem;
    border: none;
    padding-top: 1px;
    cursor: pointer;
    background-color: #f0ffff00;

    p {
      font-weight: 900;
      color: #4b4a4a;
      transition: ease-in 0.2s;
    }

    p:hover {
      color: #000;
    }

  }
}

.form-whatsapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;

  button {
    width: 200px;
    margin-top: 1rem;
    height: 1.8rem;
    background-color: #64b161;
    border: none;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    transition: ease-in 0.2s;
  }

  button:hover {
    background-color: #3B7339;
  }

  .whatsapp-icon {
    width: 16px;
    height: 16px;
    margin-right: 0.2rem;
  }

}

.input-container-whatsapp {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: bold;


  label {
    font-size: 0.9rem;
    color: #282c34;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }

  input {
    width: 200px;
    height: 25px;
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
    border-radius: 0;
    border: 1px solid #bbbbbb;
    background-color: #f8f9fa;
    border-radius: 0.2rem;
    font-size: 0.8rem;
    outline: none;
  }

  input::placeholder {
    font-size: 0.8rem;
  }

  p {
    color: #b23a48;
    font-size: 0.8rem;
    height: 20px;
  }
}

@media (max-width: 535px) {
  .whatsapp-modal-container {
    width: 400px;
  }
}

@media (max-width: 425px) {
  #modal-whatsapp{
    h2{
      font-size: 1rem;
      max-width: 280px;
    }
  }

  .whatsapp-modal-container {
    width: 300px;
  }
}
