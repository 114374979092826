.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.5rem;
    padding-top: 3em;
    -webkit-box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.2);
    box-shadow: 2px 2px 3px 2px rgba(0,0,0,0.2);
}

.cardIcon {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    .roundIcon {
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        padding: 1.3em;
        top: -50px;
    }

    img {
        width: 70px;
        color: $secondary-color;
    }
}

.cardBody {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 1.25rem 1.25rem 1.25rem;
    z-index: 2;
}

.cardTitle {
    color: $base-color;
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 0.75rem;
}

.cardText {
    color: $secondary-color;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 1.6em;
    margin-bottom: 0;
}

.cardForm {
    text-align: left;
    padding-top: 1rem;

    label {
        color: $base-color;
    }
}

@media screen and (max-width: 485px) {

    .card {
        margin-bottom: 1rem;
    }

    .cardTitle {
        font-size: 1.6em;
    }
   
  }

  @media screen and (max-width: 376px) {

    .card {
        margin-bottom: 2rem;
    }

    .cardBody {       
        padding: 0 1rem 1rem 1rem;       
    }

    .cardTitle {
        font-size: 1.4em;
    }
   
  }

  @media screen and (max-width: 320px) {

    .card {
        margin-bottom: 5rem;
    }
   
  }