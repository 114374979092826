@import '../../../../../styles/colors.scss';

.simulation-result {
  display: none;

  z-index: 10001 !important;
  color: black !important;

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    color: #FFF;
  }

  .modal-body {
    background-color: $base-color-light;
    overflow-y: auto;

    .linkDownloadTermIn100 {
      color: $base-color;
      padding: 0;
      font-size: 1.5rem;
      display: inline;
    }

    .text-muted {
      color: rgba(255, 255, 255, 0.5) !important;
    }

    .react-html5-camera-photo video {
      width: 100%;
    }

    .stepBar {
      margin-bottom: 30px;
      overflow: hidden;
      color: lightgrey;
    }

    .stepBar .active {
      color: #fff;
    }

    .stepBar li {
      list-style-type: none;
      font-size: 12px;
      width: 25%;
      float: left;
      position: relative;
      z-index: 1;
    }

    .stepBar li i:before {
      width: 50px;
      height: 50px;
      line-height: 45px;
      display: block;
      font-size: 18px;
      color: #ffffff;
      background: lightgray;
      border-radius: 50%;
      margin: 0 auto 10px auto;
      padding: 2px;
    }

    .stepBar li:after {
      content: '';
      width: 100%;
      height: 2px;
      background: lightgray;
      position: absolute;
      left: 0;
      top: 25px;
      z-index: -1;
    }

    .stepBar li.active i:before,
    .stepBar li.active:after {
      background: skyblue;
    }
  }

  .button-document{
    width: 20rem !important;
  }

  .react-pdf__Page__canvas {
    margin: 10px auto;
    overflow-y: scroll;
    border: thin solid black;
  }

  .modal-header {
    background-color: $base-color;
    border-bottom: none;
    width: 100%;
    align-items: center;
  }

  .modal-header .modal-title-logo {
    background-color: #FFF;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: .25rem;
  }

  .modal-header .btn-close {
    margin-bottom: 0;
    line-height: 1.5;
    cursor: pointer;
  }

  .modal-footer {
    display: block;
    background-color: $base-color;
    border-top: none;
    text-align: center;

    .container {
      margin-right: auto;
    }
  }

  .backgroundLogo {
    background-color: #FFF;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: .25rem;
  }

  .button-accept {
      width: 10rem;
      margin: 0 auto;
  }


  .title {
    font-weight: bold;
  }

  .box {
    text-align: left;
    padding: 3px;
    margin: 0 30%;
  }

  .box:hover {
    background-color: #d1d1d1;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: $base-color;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #FFF;
    color: #000;
  }
}

.simulation-result.visible {
  display: block;
}

.modal-backdrop {
  z-index: 10000 !important;
  opacity: 0.9;
}

.loaderLogo {
  background-image: url('../../../../../assets/logo/logo.svg');
  width: 100px;
  height: 116px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -58px;
  margin-left: -50px;
  z-index: 9999999999;

  animation-duration: 2s;
  animation-name: changeGrayscale;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changeGrayscale {
  from {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  to {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.btn-primary {
  color: #fff;
  background-color: $base-color;
  border-color: $base-color;
}

.btn-primary:hover {
  background-color: #172747;
  border-color: #172747;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $base-color;
  border-color: $base-color;
}

.btn-secondary {
  color: #fff;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.btn-secondary:hover {
  background-color: #774a18;
  border-color: #774a18;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: $base-color;
  border-color: $base-color;
}

.btn-light {
  color: #fff;
  background-color: $base-color-light;
  border-color: $base-color-light;
}

.btn-light:hover {
  background-color: #064248;
  border-color: #064248;
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: $base-color-light;
  border-color: $base-color-light;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.3rem;
  font-size: 1.2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #000;
}

// PRICE TABLE
.pricingTable10 {
  margin-bottom: 15px;
  text-align: center;
}

.pricingTable10 .pricingTable-header {
  padding: 30px 0;
  background: $base-color;
  position: relative;
  transition: all .3s ease 0s;
}

// .pricingTable10:hover .pricingTable-header {
//   background: $base-color;
// }

.pricingTable10 .pricingTable-header:after,
.pricingTable10 .pricingTable-header:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #d9d9d8;
  position: absolute;
  bottom: 12px;
}

.pricingTable10 .pricingTable-header:before {
  left: 40px;
}

.pricingTable10 .pricingTable-header:after {
  right: 40px;
}

.pricingTable10 .heading {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 0;
}

.pricingTable10 .price-value {
  display: inline-block;
  position: relative;
  font-size: 40px;
  font-weight: 700;
  color: $secondary-color;
  // transition: all .3s ease 0s;
}

// .pricingTable10:hover .price-value {
//   color:#fff
// }

.pricingTable10 .currency {
  font-size: 22px;
  font-weight: 700;
  position: relative;
  top: -20px;
}

.pricingTable10 .cents {
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}

.pricingTable10 .info-simulation {
  text-align: left;
  line-height: 12px;
  font-size: 70% !important;
  font-weight: normal !important;
}

.pricingTable10 .pricing-content {
  padding-top: 50px;
  padding-bottom: 25px;
  background: #fff;
  position: relative;

  span {
    color: $secondary-color;
    font-weight: 800;
  }
}

.pricingTable10 .pricing-content:after,
.pricingTable10 .pricing-content:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #7c7c7c;
  position: absolute;
  top: 12px;
}

.pricingTable10 .pricing-content:before {
  left: 40px;
}

.pricingTable10 .pricing-content:after {
  right: 40px;
}

.pricingTable10 .pricing-content ul {
  padding: 0 15px;
  margin: 0;
  list-style: none;
}

.pricingTable10 .pricing-content ul:after,
.pricingTable10 .pricing-content ul:before {
  content: "";
  width: 8px;
  height: 46px;
  border-radius: 3px;
  background: linear-gradient(to bottom, #818282 50%, #727373 50%);
  position: absolute;
  top: -22px;
  box-shadow: 0 0 5px #707070;
  // transition: all .3s ease 0s;
}

// .pricingTable10:hover .pricing-content ul:after,
// .pricingTable10:hover .pricing-content ul:before {
//   background: linear-gradient(to bottom, #11b8c7 50%, $base-color-light 50%);
// }

.pricingTable10 .pricing-content ul:before {
  left: 44px;
}

.pricingTable10 .pricing-content ul:after {
  right: 44px;
}

.pricingTable10 .pricing-content ul li {
  font-size: 15px;
  font-weight: 500;
  color: #777473;
  padding: 10px 0;
  border-bottom: 1px solid #d9d9d8;
}

.pricingTable10 .pricing-content ul li:last-child {
  border-bottom: none;
}

@media screen and (max-width: 990px) {
  .pricingTable10 {
    margin-bottom: 25px;
  }
}
