@import '../../../styles/colors.scss';

section.new-faq {
    background-color: #fff;
    color: $base-color;
    padding: 3em 0;

    h2 {
        color: $base-color;
    }

    .partner-fgts {
      h5 {
        color: #717171;
      }

      h2 {
        color: #000;
      }

      .accordion__section {
        border-color: #717171 !important;
      }

      .accordion__icon {
        color: #000;
      }

      .accordion__title {
        color: #000 !important;
      }

      .accordion__text {
        color: #717171 !important;
      }
    }
}
