@import '../../../styles/colors.scss';

section.testimonial {
    padding: 3rem 0;

    h2 {
        color: $base-color;
    }

    .rec button {
        display: none;
    }

    .testimonialItem {
        text-align: left;
        padding: 0.6em;

        .content {
            padding: 1.5em;
            background-color: #fff;
            border-radius: 5px;
            -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);

            .icon {
                color: $base-color-light;
                font-size: 1.3em;
            }

            p {
                min-height: 200px;
                color: $base-color;
                font-size: 1.1em;
                font-weight: 300;
                font-style: italic;
                line-height: 1.8em;
            }

            .info {
                font-size: 0.8em;
                color: $base-color-light;
                font-weight: 300;
                line-height: 1.5em;

                .rating {
                    color: $secondary-color;
                    padding-top: 0.3em;

                    span {
                        padding: 0 0.1em;
                    }
                }
            }
        }
    }
}
