@import '../../../styles/colors.scss';

*
{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

body
{
	overflow-x: hidden;
}

.containerL
{
	position: relative;
	width: 100%;
    text-align: left;
}

.navigation
{
	position: fixed;
	width: 300px;
	height: 100%;
	background: $base-color;
	transition: 0.5s;
	overflow: hidden;
}

.navigation.active
{
	width: 60px;
}

.navigation ul
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.navigation ul li
{
	position: relative;
	width: 100%;
	list-style: none;
}

.navigation ul li:hover
{
	background: #fff;

    a {
        color: $base-color;

        .icon {
            .fa {
                color: $base-color;
            }
        }
    }
}

.navigation ul li.active
{
	background: #fff;

    a {
        color: $base-color;

        .icon {
            .fa {
                color: $base-color;
            }
        }
    }
}

.navigation ul li:nth-child(1)
{
    background-color: #fff;
	margin-bottom: 20px;
}

.navigation ul li a
{
	position: relative;
	display: block;
	width: 100%;
	display: flex;
	text-decoration: none;
	color: #fff;
}

.navigation ul li .linkLogo {
    height: 85px;
    align-items: center;
}

.navigation ul li a .logo-default
{
    height: 66px;
	padding: 15px 15px 15px 15px;
}

.navigation ul li a .logo-default.active
{
    display: none;
}

.navigation ul li a .logo-menu-toggle
{
    height: 65px;
	padding: 15px 15px 15px 15px;
    display: none;
}

.navigation ul li a .logo-menu-toggle.active
{
    display: block;
}

.navigation ul li .division
{
    position: absolute;
    width: 100%;
}

.navigation ul li .divisionT
{
    width: 100%;
    background-color: $base-color-light;
    padding: 2px 0;
}

.navigation ul li .divisionT:after {
    content: '';
    display: block;
    margin-top: 1px;
    border-bottom: 2px dotted $secondary-color;
}

.navigation ul li a .icon
{
	position: relative;
	display: block;
	min-width: 60px;
	height: 60px;
	line-height: 70px;
	text-align: center;
}

.navigation ul li .icon .fa
{
	color: #fff;
	font-size: 24px;
}

.navigation ul li a .title
{
	position: relative;
	display: block;
	padding: 0 10px;
	height: 60px;
	line-height: 60px;
	white-space: nowrap;
}

.main
{
	position: absolute;
	width: calc(100% - 300px);
	left: 300px;
	min-height: 100vh;
	background: #f5f5f5;
	transition: 0.5s;
}

.main.active
{
	width: calc(100% - 60px);
	left: 60px;
}

.main .topbar
{
	width: 100%;
	background: #fff;
  border: 1px solid #D9D9D9;
	height: 90px;
	padding: 0 10px;
	display: flex;
	align-items: center;
  justify-content: space-between;
}

.toggle
{
	position: relative;
	width: 60px;
	height: 60px;
	cursor: pointer;
}
.toggle::before
{
	content: '\f0c9';
	font-family: fontAwesome;
	position: absolute;
	width: 100%;
	height: 100%;
	line-height: 50px;
	font-size: 24px;
	text-align: center;
	color: #111;
}

.info-page
{
	position: relative;
	margin: 0 10px;
  color: red;
}

.info-page .breadcrumb {
    font-size: 0.875rem;
    color: #8C8C8C;
    padding-bottom: 5px;
}

.info-page .title-page {
    font-size: 1.25rem;
    color: $base-color;
}

.content {
    position: relative;
	width: 100%;
	padding: 20px;
    color: black;
}

.cardBox
{
	position: relative;
	width: 100%;
	padding: 20px;
	display: grid;
	grid-template-columns: repeat(2,1fr);/*4*/
	grid-gap: 20px;
}

.cardBox .card
{
	position: relative;
	background: #fff;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.cardBox .card .numbers
{
	position: relative;
	font-size: 2em;
	font-weight: 500;
    color: black;
}

.cardBox .card .cardName
{
	color: #999;
}

.cardBox .card .iconBox
{
	font-size: 2.5em;
	color: $base-color;
}

@media(max-width: 992px)
{
	.navigation
	{
		left: -300px;
	}

	.navigation.active
	{
		left: 0;
		width: 300px;
	}

	.main
	{
		width: 100%;
		left: 0;
	}

	.main.active
	{
		width: calc(100% - 300px);
		left: 300px;
	}

	.cardBox
	{
		grid-template-columns: repeat(2,1fr);
	}
}

@media(max-width: 480px)
{
	.cardBox
	{
		grid-template-columns: repeat(1,1fr);
	}

	.navigation
	{
		width: 100%;
		left: -100%;
		z-index: 1000;
	}

	.navigation.active
	{
		width: 100%;
		left: 0;
	}

    .navigation ul li a .logo-default.active
    {
        display: block;
    }

    .navigation ul li a .logo-menu-toggle.active
    {
        display: none;
    }

	.toggle.active
	{
		position: fixed;
		z-index: 10000;
		right: 0;
		left: initial;
	}

	.toggle.active::before
	{
		color: $base-color;
	}

	.main,
	.main.active
	{
		width: 100%;
		left: 0;
	}
}
