@import '../../../styles/colors.scss';

section.slider-page-home {
    height: 101vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    .carousel-products {
        position: absolute;
        width: 100%;
        bottom: -20px;
        left: 0;
    }

    .rec.rec-slider-container {
        margin: 0;
    }

    .banner {
        display: flex;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        min-height: 101vh;
        opacity: 1;
        visibility: inherit;

        .banner-content {
            margin: auto;

            h2 {
                font-family: 'Roboto', sans-serif;
                font-size: 2.3em;
                text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
            }

            .box-banner-1 {
                .title {
                    color: $text-blue;
                    font-weight: 500;
                }
            }

            .box-banner-2 {
                text-align: right;

                .title {
                    color: #fff;
                    font-weight: 500;
                }

                .subtitle {
                    color: $base-color;
                    font-size: 3.5em;
                    font-weight: 900;
                }
            }

            .box-banner-3 {
                padding-left: 8em;

                .title {
                    color: #fff;
                    font-weight: 500;
                }

                .subtitle {
                    color: $base-color;
                    font-weight: 900;
                }
            }
        }
    }

    .product-card {
        .card {
            font-family: 'Poppins', sans-serif;
            background-color: #fff;
            margin: 0 1rem;
            border-radius: 10px;
            text-align: center;
        }

        .card-body {
            padding: 0.8em 1em;

            .card-icon {
                color: $secondary-color !important;
                display: inline !important;
                max-height: 45px;
                margin-bottom: 5px;
            }

            .card-text {
                color: $base-color !important;
                margin: 10px 0;
            }

            .card-title {
                margin-top: 5px;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 1.4em;
            }

            .card-description {
                height: 61px;
                font-size: 1em;
            }
        }
    }
}
