@import '../../../styles/colors.scss';
@import '../../../styles/card_iconed.scss';

section.home {
    height: 101vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}






