@import '../../../styles/colors.scss';

section.about {
    background-color: $base-color-light;
    padding: 3rem 0;

    .iconsList {
        justify-content: center;

        .icon {
            font-size: 4em;
            color: $secondary-color;
            margin: 0.3em 0;
        }

        h2 {
            color: $base-color;
        }

        p {
            color: #fff;
            font-size: 1.2em;
            font-weight: 400;
            line-height: 1.6em;
            text-align: left;
        }
    }

    @media screen and (max-width: 768px) {}
}
