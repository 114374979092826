.btn {
    display: inline-block;
    font-weight: 700;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 1.375rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 0.5rem 2rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 3rem;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
    width: 100%;
}

.btn-primary {
    color: #fff;
    background-color: $secondary-color;
    border-color: $secondary-color;
}

.btn-primary:hover {
    color: #fff;
    background-color: darken($color: $secondary-color, $amount: 20);
    border-color: darken($color: $secondary-color, $amount: 25);
    transition: all 0.2s ease;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: darken($color: $secondary-color, $amount: 20);
    border-color: darken($color: $secondary-color, $amount: 25);
    // box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: $secondary-color;
    border-color: $secondary-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: darken($color: $secondary-color, $amount: 25);
    border-color: darken($color: $secondary-color, $amount: 20);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    // box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-whatsapp {
    color: #fff;
    background-color: #64b161;
    border-color: #64b161;
}

.btn-whatsapp:hover {
    color: #fff;
    background-color: darken($color: #64b161, $amount: 20);
    border-color: darken($color: #64b161, $amount: 25);
    transition: all 0.2s ease;
}

.btn-whatsapp:focus,
.btn-whatsapp.focus {
    color: #fff;
    background-color: darken($color: #64b161, $amount: 20);
    border-color: darken($color: #64b161, $amount: 25);
    // box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-whatsapp.disabled,
.btn-whatsapp:disabled {
    color: #fff;
    background-color: #64b161;
    border-color: #64b161;
}

.btn-whatsapp:not(:disabled):not(.disabled):active,
.btn-whatsapp:not(:disabled):not(.disabled).active,
.show > .btn-whatsapp.dropdown-toggle {
    color: #fff;
    background-color: darken($color: #64b161, $amount: 25);
    border-color: darken($color: #64b161, $amount: 20);
}

.btn-whatsapp:not(:disabled):not(.disabled):active:focus,
.btn-whatsapp:not(:disabled):not(.disabled).active:focus,
.show > .btn-whatsapp.dropdown-toggle:focus {
    // box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
