@import '../../../styles/colors.scss';

section.about {
    background-color: #fff;
    padding: 3rem 0;

    h2 {
        color: $base-color-light;
    }

    p {
        color: $base-color;
    }

    p.subtitle {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.7em;
        padding-bottom: 4em;
    }

    .row {
        text-align: left;
        margin: 0;

        .colLeft {
            padding-left: 0;
        }

        .divButtonWhatsApp {
            padding-top: 1em;

            button {
                display: flex;
                align-items: center;
            }

            .icon {
                font-size: 2em;
                margin-right: 0.3em;
            }
        }

        .colColorBlue {
            clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
            background-color: $base-color-light;
            display: flex;
            align-items: center;
        }

        .people {
            position: absolute;
            padding-left: 1.7em;
            z-index: 2;
            height: 100%;
            bottom: 30px;
        }

        h4 {
            font-size: 2em;
            font-weight: 900;
            text-align: left;
            padding: 2em 0em 2em 7em;
            width: 85%;
        }

        ul {
            color: $base-color;
        }

        ul li {
            display: flex;
            align-items: flex-start;
            list-style: none;
            font-weight: 400;
            font-size: 1.3em;
            margin-bottom: 1.3em;

            a {
                color: $base-color;
                text-decoration: none;
            }

            span {
                padding-right: 5px;
            }

            .icon {
                color: $base-color-light;
                font-size: 1.3em;
            }
        }
    }

    @media screen and (max-width: 768px) {
        ul {
            padding-top: 2em;
        }

        .colColorBlue {
            span {
                h4 {
                    font-size: 1.5em;
                }
            }
        }
    }
}
