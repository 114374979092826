@import '../../../styles/colors.scss';

section.new-about-more {
  background-color: $primary-fgts-color;
  padding: 3em 0;

  h5 {
    color: #fff;
  }

  h2 {
    color: $base-color;
  }

  .benefits-fgts {
    color: $base-color;

    .icon {
      font-size: 3em;
      color: #fff;
      margin: 0.3em 0;
    }

    h6 {
      font-size: 1.6em;
      font-weight: 300;
      padding-bottom: 0.6em;
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-size: 0.9em;
      font-weight: 500;
      line-height: 1.2em;
    }
  }

  .obs {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9em;
    font-weight: 500;
    color: $base-color;
    text-align: left;
    padding-top: 2em;
  }
}

section.new-about-more.partner-fgts {
  background-color: #b2b2b2 !important;

  h2 {
    color: #000;
  }

  .obs {
    color: #fff;
  }

  .benefits-fgts {
    h6 {
      color: #fff;
    }

    p {
      color: #fff;
    }

    .icon {
      color: #000 !important;
    }
  }
}
