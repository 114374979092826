.outter-container {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;

    .page-not-found-container {
        display: flex;
        border-radius: 5px;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #f0f8ff;
        color: #3a6491;
        width: 500px;
        margin: 0 auto;
        height: 500px;

        h1 {
            font-size: 24px;
            font-weight: 700;
        }

        a {
            padding: 5px;
            color: #D99448;
            transition: ease-in-out 0.4s;
            border-radius: 5px;
            font-weight: 700;
        }       

        img {
            width: 160px;
        }
    }
}

@media screen and (max-width: 600px) {

    .outter-container {

        .page-not-found-container {
            width: 400px;

            h1 {
                font-size: 22px;
                font-weight: 700;
            }

            p {
                font-size: 15px;
            }

            a {
                font-size: 15px;
            }

            img {
                width: 140px;
            }
        }
    }

    @media screen and (max-width: 450px) {

        .outter-container {

            .page-not-found-container {
                width: 350px;
                height: 300px;

                h1 {
                    font-size: 20px;
                    font-weight: 700;
                }

                p {
                    font-size: 14px;
                }

                a {
                    font-size: 14px;
                }

                img {
                    width: 120px;
                }
            }
        }
    }

    @media screen and (max-width: 400px) {

        .outter-container {

            .page-not-found-container {
                width: 300px;

                h1 {
                    font-size: 18px;
                    font-weight: 700;
                }

                p {
                    font-size: 12px;
                }

                a {
                    font-size: 12px;
                }

                img {
                    width: 100px;
                }
            }
        }
    }
}