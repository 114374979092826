.status-container {
  margin-top: 1rem;
  height: 20px;
  width: 20px;

  p {
    width: 75px;
    font-size: 0.8rem;
    text-align: left;
  }
}

.progress-bar-container {
  margin-bottom: 1rem;
  display: flex;
}

.RSPBprogressBar {
  width: 600px;
}

@media screen and (max-width: 768px) {
  .status-container {
    p {
      font-size: 0.7rem;
    }
  }

  .RSPBprogressBar {
    width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .status-container {
    p {
      font-size: 0.7rem;
      writing-mode: vertical-rl;
      text-align: left;
      width: auto;
      margin-bottom: 2rem;
    }
  }

  .RSPBprogressBar {
    width: 320px;
  }
}
