@import '../styles/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #fff;
  color: #fff;
}

body, input, button, textarea {
  font: 400 16px 'Roboto', sans-serif;
}

section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2.1em;
    font-weight: 300;
    line-height: 1.2em;
    padding-bottom: 1em;
    color: #fff;
}

section h5 {
    font-size: 1em;
    font-weight: 400;
    padding-bottom: 1em;
    color: $secondary-color;
}
