@import '../../styles/colors.scss';

nav {
  background: #fff;
  height: 70px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-box-shadow: 0 10px 10px -10px rgba(15, 15, 15, 0.2);
  box-shadow: 0 10px 10px -10px rgba(15, 15, 15, 0.2);

  .partner-fgts {
    ul li a span {
      color: #000 !important;
    }

    ul li a {
      color: #717171;
    }
  }
}

.div-logo {
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    color: #95a5a6;
    font-size: 0.5rem;
    margin-top: 0.1rem;
    text-align: left;
    white-space: nowrap;
  }
}

.div-logo-volus {
  display: flex;
  width: 100%;
  align-items: center;

  p {
    font-size: 0.6rem;
    color: #000;
    max-width: 130px;
    font-weight: lighter;
    text-align: left;
    padding-left: 0.5rem;
    border-left: 2px solid #414141b0;
    font-style: italic;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.septemberYellom a,
.septemberYellom span,
.septemberYellom.nav-mobile-icon {
  color: #fdcb6e !important;
}

.octoberPink a,
.octoberPink span,
.octoberPink.nav-mobile-icon {
  color: #fd79a8 !important;
}

.novemberBlue a,
.novemberBlue span,
.novemberBlue.nav-mobile-icon {
  color: #3498db !important;
}

.nav-logo {
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  img {
    max-width: 130px;
  }

  .preventionLogo {
    max-height: 50px;
    -webkit-box-shadow: 0 10px 10px -10px rgba(15, 15, 15, 0.2);
    box-shadow: 0 10px 10px -10px rgba(15, 15, 15, 0.2);
  }

  .partner-logo {
    max-height: 50px;
  }
}

.nav-mobile-icon {
  display: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 70px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
}

.nav-menu {
  display: flex;
  justify-content: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
}

.nav-menu li {
  height: 70px;
}

.nav-menu li a,
.nav-menu li span {
  color: $base-color;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.navbar a:hover,
.subnav:hover .subnavbtn {
  background-color: #fff;
}

.nav-menu .subnav-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: #fff;
  width: 100%;
  z-index: 1;
  -webkit-box-shadow: 0 10px 10px -10px rgba(15, 15, 15, 0.2);
  box-shadow: 0 10px 10px -10px rgba(15, 15, 15, 0.2);
}

.nav-menu .subnav-content a {
  justify-content: center;
  text-decoration: none;
  padding: 0.5rem 0;
}

.nav-menu .subnav-content a:hover {
  background-color: #eee;
}

.nav-menu .subnav:hover .subnav-content {
  display: flex;
  flex-direction: column;
}

.nav-buttons {
  display: flex;
  justify-self: flex-end;
}

.nav-buttons a {
  color: $secondary-color;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-buttons a:hover {
  color: $base-color-light;
}

@media screen and (max-width: 960px) {
  nav {
    transition: 0.8s all ease;
  }
}

@media screen and (max-width: 768px) {
  .nav-mobile-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: $base-color;
  }

  .nav-menu,
  .nav-buttons {
    display: none !important;
  }
}

@media screen and (max-width: 425px) {
  .navbar-container {
    padding: 0;
  }

  .div-logo-volus {
    p {
      max-width: 110px;
      font-size: 0.5rem;
    }
  }

  .nav-logo {
    img {
      width: 80px;
    }

    .preventionLogo {
      width: 29px;
      height: 50px;
    }
  }
}

@media screen and (max-width: 376px) {
  .div-logo-volus {
    p {
      font-size: 0.4rem;
      max-width: 100px;
    }
  }
  .nav-logo {
    img {
      width: 60px;
    }
  }
}

@media screen and (max-width: 336px) {
  .nav-logo {
    img {
      width: 55px;
    }
  }
}
