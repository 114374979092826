@import '../../../styles/colors.scss';

section.new-simulate-now {
    background-color: $base-color;
    padding: 3em 0;

    h3 {
        font-size: 1.7em;
        font-weight: 500;
        color: $primary-fgts-color;
        text-align: left;
    }

    @media screen and (max-width: 768px) {
        h3 {
            font-size: 1.3em;
            padding-bottom: 1em;
            text-align: center;
        }
    }
}

section.new-simulate-now.partner-fgts {
  background-color: #000 !important;

  h3 {
    color: #fff;
  }

  .btn-primary {
    background-color: #fff;
    color: #000;
    border: #fff;
  }
}
