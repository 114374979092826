@import '../../../styles/colors.scss';

section.faq {
    background-color: #fff;
    color: $base-color;
    padding: 3em 0;

    h2 {
        color: $base-color;
    }
}
