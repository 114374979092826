@import '../../../styles/colors.scss';
.btn-whatsapp-fixed{
  position: fixed;
    bottom: 10vh;
    right: 1vh;
    z-index: 999;
}

@media only screen and (min-width: 600px) { 
  .btn-whatsapp-fixed{
    position: fixed;
      bottom: 1vh;
      right: 1vh;
      z-index: 999;
  }
}

.button-tnb {
    background-color: $secondary-color;
    font-size: 20px !important;
    padding: 12px 34px !important;
    border-color: $secondary-color !important;
    font-size: 24px;
    font-family: Roboto;
}

.button-tnb:hover {
    background-color: $secondary-color;
  }

.btn-hvr-effect {
    background-color: #ad7335 !important;
  }