@import '../../../styles/colors.scss';

section.simulateNow {
    background-color: #4069C2;
    padding: 3em 0;
    display: flex;

    .colLeft {
        text-align: left;

        h3 {
            font-family: 'Poppins', sans-serif;
            font-size: 2em;
            font-weight: 300;
            padding-bottom: 0.5em;
        }

        p {
            font-size: 1.5em;
            font-weight: 300;
            line-height: 1.6em;
            padding-bottom: 1.3em;
        }
    }

    @media screen and (max-width: 768px) {
        .colLeft {
            padding-bottom: 2em;
        }
    }
}
