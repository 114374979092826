.terms-of-use {
    padding-top: 7rem;
    padding-bottom: 7rem;
    color: #000;
    text-align: left;

    .header {
        p {
            margin-bottom: 1rem;
        }
    }

    .footer {
        margin: 2rem auto;
        border-top: 1px solid #333;
        text-align: center;

        h5 {
            color: #000;
            font-weight: 400;
            font-size: 1rem;
            padding-top: 1rem;
        }
    }

    h1 {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 2rem;
    }

    section {
        margin: 2rem auto 3rem;

        h3 {
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }

        p {
            padding: 0 1rem;
            margin-bottom: 1rem;
        }

        ul {
            list-style-type: none;
            margin-bottom: 1.5rem;

            li {
                padding: 0 2rem;
                margin-bottom: 1rem;
            }
        }
    }
}
