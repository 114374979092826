@import '../../../styles/colors.scss';

section.contact {
    background-color: $base-color;
    padding: 3rem 0;

    h2 {
        color: #fff;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    .row {
        text-align: left;
        margin: 0;

        h2 {
            font-weight: 400;
            padding-bottom: 0.5em;
        }

        h5 {
            color: #fff;
            font-size: 1.4em;
            font-weight: 300;
        }

        .contactInfo {
            font-weight: 300;
            font-size: 0.9em;
            display: flex;
            align-items: center;
            padding-bottom: 1em;

            .icon {
                padding-right: 0.6em;
                font-size: 1.5em;
            }
        }

        .colLeft {
            padding: 2em 5em;

            .peoplePhone {
                position: absolute;
                top: -13em;
                left: 2em;
                height: 13em;
            }

            .divButtonWhatsApp {
                padding-top: 1em;

                button {
                    display: flex;
                    align-items: center;
                }

                .icon {
                    font-size: 2em;
                    margin-right: 0.3em;
                }
            }
        }

        .colRight {
            padding: 2em 5em;
        }

        .colColorBlue {
            clip-path: polygon(13% 0%, 100% 0%, 100% 100%, 13% 100%, 0% 50%);
            background-color: #4877DB;
        }
    }

    @media screen and (max-width: 768px) {
        .row {
            .colRight {
                padding: 2em 4em;
            }
        }
    }
}
