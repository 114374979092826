@import '../../../../styles/colors.scss';
@import '../../../../styles/table.scss';

.admin.balance {
    .form-group {
        text-align: left;

        label {
            display: flex;
            padding-bottom: .5rem;
        }
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
        text-align: left;
    }

    table, th, td {
        border: none;
    }

    table thead {
        background-color: $base-color;
        color: white;
    }

    table tbody .unchecked {
        color: #e74c3c;
        text-decoration: line-through;
    }
}
