@import '../../../styles/colors.scss';
@import '../../../styles/table.scss';

.container .my-loans {
  .col-md-12 {
    width: 800px;
  }

  .title-list {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .accordion-container {
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .accordion-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 600px;
      height: 50px;
      border-radius: 0.2rem;
      padding: 0.5rem;
      margin: 0.5rem;
      border: 2px solid #504e90;
      cursor: pointer;
    }

    .accordion-icon-opened {
      width: 1rem;
      rotate: 90deg;
    }

    .accordion-icon-closed {
      width: 1rem;
      rotate: 180deg;
    }

    .accordion-panel {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 120px;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .container .my-loans {
    .accordion-container {
      width: 450px;

      .accordion-button {
        width: 450px;
        font-size: 0.8rem;

        span {
          width: 80px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container .my-loans {
    .col-md-12 {
      padding: 0;
    }
    .accordion-container {
      width: 320px;

      .accordion-button {
        width: 320px;
        font-size: 0.6rem;

        span {
          width: 80px;
        }
      }
    }
  }
}
