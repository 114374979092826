@import '../../../styles/colors.scss';

section.new-step-by-step {
  background-color: #fff;
  padding: 3em 0;

  .partner-fgts {
    h5 {
      color: #717171;
    }

    h2 {
      color: #000;
    }

    .rec.rec-arrow {
      background-color: #b2b2b2;
    }

    .rec.rec-arrow:focus {
      background-color: #b2b2b2;
    }

    .rec.rec-arrow:hover {
      background-color: #717171;
    }

    .rec.rec-dot_active {
      background-color: #b2b2b2;
      box-shadow: 0 0 1px 3px #717171;
    }

    .rec.rec-dot:hover {
      box-shadow: 0 0 1px 3px #717171;
    }
  }

  .rec-carousel-item img {
    width: 100%;
    padding: 0.2em;
    cursor: pointer;
  }

  .rec.rec-arrow:hover {
    background-color: $primary-fgts-color;
  }

  .rec.rec-arrow:focus {
    background-color: $primary-fgts-color;
  }

  .rec.rec-arrow:disabled {
    visibility: hidden;
  }

  .rec.rec-dot_active {
    background-color: #ffa03b88;
    box-shadow: 0 0 1px 3px $primary-fgts-color;
  }

  .rec.rec-dot:hover {
    box-shadow: 0 0 1px 3px $primary-fgts-color;
  }

  @media screen and (max-width: 768px) {
    .rec-carousel-item img {
      padding: 0;
    }

    .rec.rec-arrow {
      width: 40px;
      height: 40px;
      min-width: 40px;
      line-height: 40px;
    }
  }
}
