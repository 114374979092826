@import '../../../styles/colors.scss';

.containerL {
  .content .service-channel {
    display: flex;

    .people-container {
      display: flex;
    }

    .box {
      margin-left: 20px;
      padding: 20px;
      width: 100%;
      background-color: $base-color;
      color: #fff;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      a {
        color: #fff;
        text-decoration: none;
      }

      h2 {
        font-size: 1.875em;
        font-weight: 300;
        padding-bottom: 30px;
      }

      .infos {
        height: 100%;

        .contact-info {
          font-weight: 300;
          font-size: 0.9em;
          display: flex;
          align-items: center;
          padding-bottom: 1em;

          .icon {
            padding-right: 0.6em;
            font-size: 1.5em;
          }
        }

        .div-button-whatsapp {
          padding-top: 1em;
          display: flex;

          button {
            display: flex;
            align-items: center;
            font-size: 16px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          }

          .icon {
            font-size: 25px;
            margin-right: 0.3em;
          }
        }
      }
    }
  }

}

@media(max-width: 480px) {
  .containerL {
    .content {
      .service-channel {
        .box {
          h2 {
            font-size: 1.2em;
          }

          .infos {
            .div-button-whatsapp {
              button {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}
