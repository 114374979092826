.concluded-back-home-link {
  display: flex;
  align-items: center;
  margin: 20px;

  .left-chevron {
    cursor: pointer;
    height: 1.2rem;
    margin-right: -0.2rem;
  }

  a {
    color: #fff;
    font-size: 1rem;
    margin-left: 2px;
  }
}

.info-concluded {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  margin-top: -20px;

  h3 {
    margin: 10px;
    font-size: 30px;
  }

  p {
    text-align: center;
    line-height: 30px;
    max-width: 600px;
    margin: 5px;
  }

  h4 {
    margin: 20px 0;
    font-size: 25px;
  }

  .logo-container-concluded-admin {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: none;
    border-radius: 5px;
    margin-bottom: 10px;

    img {
      margin: 10px;
      background-color: #fff;
      width: 230px;
      padding: 10px;
      border-radius: 5px;
    }
  }
}

.modal-footer {
  border-top: none;
}

@media screen and (max-width: 600px) {
  .info-concluded {

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
      max-width: 400px;
    }

    h4 {
      font-size: 18px;
    }

    .logo-container-concluded-admin {
      .backgroundLogo {
        width: 60%;
        height: 60%;
        min-width: 140px;
      }
    }
  }
}
