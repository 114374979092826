@import '../../styles/colors.scss';
@import '../../styles/custom.scss';
@import '../../styles/button.scss';

section h2 {
  color: $primary-fgts-color;
}

.buttonWhatsApp {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 100;

  .icon {
    margin-top: 15px;
  }
}