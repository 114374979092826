@import '../../styles/colors.scss';
@import '../../styles/custom.scss';
@import '../../styles/button.scss';

footer {
  background-color: $base-color;
  text-align: left;
  padding: 3em 0;
  margin-top: 1em;

  a {
    color: #fff;
    text-decoration: none;
  }

  .footer-logo-social {
    position: absolute;
    display: flex;

    .footer-logo {
      position: absolute;
      top: -5.5em;
      max-height: 118px;
    }

    .social-icons {
      padding-left: 7rem;
    }

    a {
      font-size: 2em;
      padding: 0.3em;
    }
  }

  .div-button-people {
    display: flex;
    align-items: center;
    justify-content: center;

    .people-money-footer {
      position: absolute;
      top: -5em;
      right: 0;
      height: 300px;
    }
  }

  p.info-contact {
    line-height: 1.5em;
    margin-top: 4em;
  }

  .corresponding-logo {
    padding: 1.5em 0;
  }

  .extra-info p {
    font-size: 0.8rem;
    font-weight: 300;
    line-height: 1.5em;
    text-transform: uppercase;
    padding: 1em 0;
    opacity: 0.8;
  }

  .link-terms {
    padding-top: 1rem;

    .col-12 {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        font-size: 0.9rem;
        padding: 0 1rem;
      }
    }
  }
}

footer.partner-fgts {
  background-color: #717171;
  margin-top: 0;

  .btn-primary {
    background-color: #fff;
    color: #000;
    border: #fff;
  }

  .footer-logo {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

.cookie-consent-container {
  margin: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.5rem;
  position: fixed;
  left: 10px;
  right: 10px;
  bottom: 25px;
  z-index: 9999;
}

.cookie-consent-content {
  color: #000;
  padding: 0.5rem;

  a {
    color: #2f4e90;
    font-weight: bold;
  }
}

.cookie-consent-button {
  padding: 0.4rem;
  background-color: #2f4e90;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 5px;
  margin: 0.5rem;
  cursor: pointer;
}

@media screen and (max-width: 1212px) {
  .cookie-consent-content {
    max-width: 800px;
    text-align: center;
    padding: 0.5rem 0;
  }
}

@media screen and (max-width: 996px) {
  .cookie-consent-content {
    max-width: 600px;
  }
}

@media screen and (max-width: 795px) {
  .cookie-consent-content {
    padding: 0.5rem 0 0 0;
  }

  .cookie-consent-button {
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .div-button-people {
    .people-money-footer {
      top: -11em;
      height: 230px;
    }
  }
}

@media screen and (max-width: 575px) {
  .extra-info p {
    margin: 0 0.8rem;
  }
  .cookie-consent-content {
    padding: 0.5rem 0.5rem 0 0.5rem;
    font-size: 0.8rem;
  }

  .cookie-consent-button {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 414px) {
  .col-12 {
    a {
      margin: 0.5rem;
    }
  }
}
