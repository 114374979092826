@import '../../../styles/colors.scss';

.containerL .content {
  .col-md-12 {
    border: none;
    padding: 0.8rem;
  }
  form {
    h3 {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 1rem 0;
    }

    h3::before,
    h3::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid $base-color;
    }

    h3::before {
      margin-right: 0.8rem;
    }

    h3::after {
      margin-left: 0.8rem;
    }
  }
}
