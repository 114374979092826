.logo-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;


  .input-file {
    margin-top: 5px;
  }
}

.select-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  height: 74px;

  div {
    margin-top: 2px;
    label {
      margin-right: 10px;

    }
  }
}

.info-container {
  margin-top: 10px;

  .select-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  select {
    max-width: 100px;
  }
}

select {margin-top: 5px;}
