.chat-commerce {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  p {
    padding: 1rem 0;
  }
}
