@import '../../../../../styles/colors.scss';

.modal-simulator .customer-data,
.containerL .content .customer-data {
    form {
        h3 {
            display: flex;
            align-items: center;
            text-align: center;
            padding: 1rem 0;
        }

        h3::before,
        h3::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #fff;
        }

        h3::before {
            margin-right: .8rem;
        }

        h3::after {
            margin-left: .8rem;
        }
    }

    .form-control.false {
        padding: 10px;
        text-align: left;
    }
}

.modal-simulator.partner-page-simulator .customer-data {
  input,
  select {
    background-color: #717171;
    color: #fff;
    border-radius: 1rem;
    margin-top: .4rem;
  }
}
