@import '../../../styles/colors.scss';
@import '../../../styles/card_iconed.scss';

section.home {
  height: 101vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;

  .partner-fgts {
    h3 {
      color: #000000;
      text-align: left;
    }

    p {
      color: #717171;
      text-align: left;
    }

    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  .simulatedData {
    text-align: center;
    line-height: 2.5rem;
    color: #000;

    h3 {
      color: $base-color;
      font-size: 2rem;
    }

    a {
      color: $secondary-color;
    }
  }
}

.form-partner-fgts {
  position: absolute;
  bottom: -9.5rem;
  width: 100%;

  .div-inputs {
    background-color: #717171;
    margin: 0 1.5rem;
    padding: 1.4rem;
    box-shadow: 0px -5px 6px #00000059;

    p {
      text-align: left;
      margin-bottom: 1rem;
    }

    label {
      float: left;
      margin-bottom: 0.5rem;
    }

    input {
      background-color: #717171;
      color: #fff;
      border-radius: 1rem;
    }

    button {
      background-color: #fff;
      border-color: #000;
      color: #000;
      transition: 0.3s;
    }

    button:hover:not([disabled]) {
      background-color: #717171;
      border-color: #fff;
      color: #fff;
    }

    .simulatedData {
      line-height: 2.5rem;

      h3 {
        font-size: 2rem;
      }

      a {
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 467px) {
  .form-partner-fgts {
    bottom: -11rem;

    .div-inputs {
      label {
        font-size: 0.7rem;
      }

      button {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-height: 680px) {
  .form-partner-fgts {
    bottom: -13rem;
  }
}

@media screen and (max-height: 615px) {
  .form-partner-fgts {
    bottom: -15rem;
  }
}

@media screen and (max-height: 560px) {
  .form-partner-fgts {
    bottom: -17rem;
  }
}
