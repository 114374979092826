.content {
  .create-pay-info-container {
    max-width: 300px;
    margin-top: 20px;

    input {
      margin-top: 10px;
    }
  }

  .not-allowed-info-container {
    h2 {
      font-size: 16px;
      color: #3a6491;
    }
  }

  .create-pay-button-container {
    margin-top: 20px;
    max-width: 300px;

    .create-link-button {
      width: 150px;
      background-color: #3a6491;
      color: #fff;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      max-width: 200px;
      border: none;
    }
  }

  .link-info-main-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .link-info-container {
      margin: 20px 0;
      border: 2px solid #3a6491;
      border-radius: 5px;
      padding: 10px;
      width: 100%;

      .link-options-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        input {
          max-width: 600px;
        }

        .link-options-buttons-container {
          display: flex;
          align-items: center;

          .copy-link-button,
          .open-link-button {
            margin: 10px 0;
            width: 100px;
            background-color: #3a6491;
            color: #fff;
            cursor: pointer;
            padding: 10px;
            border-radius: 5px;
            max-width: 200px;
            border: none;
          }

          .open-link-button {
            margin-top: 0;
            margin-bottom: 0;
          }


          p {
            color: #3a6491;
            font-weight: 500;
            margin-left: 10px;
          }
        }


      }

      .qr-code-container {
        margin-top: 10px;

        h4 {
          margin-bottom: 10px;
        }
      }
    }
  }
}
