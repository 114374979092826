@import '../../styles/colors.scss';

.modal-simulator {
  display: none;
  z-index: 10;
  color: black;

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-header {
    background-color: $base-color;
    border-bottom: none;
    width: 100%;
    align-items: center;

    .modal-title-logo {
      background-color: #fff;
      padding: 5px;
      border: 1px solid transparent;
      border-radius: 0.25rem;
    }

    .btn-close {
      margin-bottom: 0;
      line-height: 1.5;
      cursor: pointer;
      font-size: 1rem;
    }
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    color: #fff;
  }

  .modal-body {
    background-color: $base-color-light;
    overflow-y: auto;
  }

  .modal-footer {
    display: block;
    background-color: $base-color;
    border-top: none;
    text-align: center;

    .container {
      margin-right: auto;
    }
  }
}

.modal-simulator.partner-page-simulator {
  .modal-header {
    background-color: #fff;

    .modal-title-logo {
      background-color: transparent;
      padding: 0;
      border: none;
      border-radius: 0;

      img {
        height: 40px;
      }
    }

    .btn-close {
      color: #000;
    }
  }
  .modal-body {
    background-color: #717171;
  }

  .modal-footer {
    background-color: #fff;

    button {
      background-color: #717171;
      border-color: #fff;
      color: #fff;
      transition: 0.3s;
    }

    button:hover:not([disabled]) {
      background-color: #fff;
      border-color: #000;
      color: #000;
    }
  }

  .loading-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal-simulator.visible {
  display: block;
}

.loaderLogo {
  background-image: url('../../assets/logo/logo.svg');
  width: 100px;
  height: 116px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -58px;
  margin-left: -50px;
  z-index: 9999999999;

  animation-duration: 2s;
  animation-name: changeGrayscale;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes changeGrayscale {
  from {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  to {
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
  }
}
