.header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  background-color: #2f4e90;
  height: 100px;
  border-radius: 5px;

  a {
    img {
      background-color: #fff;
      padding: 10px;
      border-radius: 5px;
    }
  }

  a {
    max-width: 120px;
    text-align: center;
    color: #2f4e90;
    margin: 10px 20px;
  }

  input {
    width: 500px;
    height: 40px;
    outline: none;
  }

  input.input::placeholder {
    opacity: 0.7;
    padding-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  .header-container {
    width: 100%;

    a {
      img {
        width: 100px;
      }
    }

    .input {
      width: 200px;
    }

    input.input::placeholder {
      font-size: 0.8rem;
    }

    a {
      font-size: 0.8rem;
    }
  }
}
