@import '../../styles/colors.scss';

/* Style the accordion section */
.accordion__section {
    display: flex;
    flex-direction: column;
    border-bottom: 1px $secondary-color solid;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: #fff;
    transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {}

/* Style the accordion content title */
.accordion__title {
    font-weight: 700;
    font-size: 1em;
    text-align: left;
    color: $secondary-color !important;
}

/* Style the accordion chevron icon */
.accordion__icon {
    font-size: 1.4em;
    transition: transform 0.6s ease;
    color: $secondary-color;
    margin-right: 1em;
}

/* Style to rotate icon when state is active */
.rotate {
    transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
    background-color: white;
    color: $base-color;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
}

/* Style the accordion content text */
.accordion__text {
    font-weight: 300;
    font-size: 0.9em;
    line-height: 2em;
    padding: 0.5em 1em 1em 4.4em;
}
