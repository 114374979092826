@import '../../../styles/colors.scss';

section.about {
    background-color: $base-color;
    padding: 3rem 0;

    h2 {
        color: #fff;
    }

    p {
        color: #fff;
        font-size: 1.2em;
        font-weight: 300;
        line-height: 2em;

        span {
            display: block;
            font-size: 1.3em;
            font-weight: 700;
            padding: 1em 0;
        }
    }

    .iconsList {
        .icon {
            font-size: 3em;
            color: $secondary-color;
            margin: 0.3em 0;
        }

        h6 {
            font-size: 1.6em;
            font-weight: 300;
            padding-bottom: 0.2em;
        }

        p {
            font-size: 0.9em;
            font-weight: 500;
        }
    }

    @media screen and (max-width: 768px) {}
}
