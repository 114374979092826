@import '../../../styles/colors.scss';
@import '../../../styles/modal.scss';

section.blog-page-home {
    padding: 3rem 0;

    .modal {
        .modal-dialog {
            border: 3px dashed #ffa03b;
        }

        .modal-header {
            border-bottom: 0;

            button {
                display: flex;
                background-color: #fff;
                border: none;
                align-items: center;
                justify-content: center;
            }
        }

        .modal-body {
            text-align: left;
            color: $base-color;
            padding: 3rem;

            h2 {
                font-family: 'Roboto', sans-serif;
                font-size: 2.5rem;
                font-weight: 700;
                padding-bottom: 0;
            }

            .blog-item-infos {
                display: flex;
                padding-top: 0.8rem;
                padding-bottom: 2.5rem;

                div {
                    display: flex;
                    align-items: center;
                    padding-right: 1rem;

                    .icon {
                        font-size: 1rem;
                    }

                    span {
                        font-size: 0.75rem;
                        font-weight: 700;
                        padding-left: 0.5rem;
                    }
                }
            }

            p {
                font-family: 'Roboto', sans-serif;
                font-size: 1.375rem;
                font-weight: 400;
            }
        }

        .divisionGreenYellow {
            width: 100%;
        }

        .modal-footer {
            border-top: 0;
        }
    }

    .rec.rec-arrow:hover {
        background-color: $primary-fgts-color;
    }

    .rec.rec-arrow:focus {
        background-color: $primary-fgts-color;
    }

    .rec.rec-arrow:disabled {
        visibility: hidden;
    }

    .rec.rec-dot_active {
        background-color: #ffa03b88;
        box-shadow: 0 0 1px 3px $primary-fgts-color;
    }

    .rec.rec-dot:hover {
        box-shadow: 0 0 1px 3px $primary-fgts-color;
    }

    h2 {
        color: $base-color;
    }

    .blog-item {
        text-align: left;
        padding: 0.6em;

        img {
            width: 100%;
            padding: 0;
        }

        .blog-item-content {
            padding: 1.5em;
            background-color: $base-color;
            border-radius: 5px;
            -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.1);

            h3 {
                font-family: 'Poppins', sans-serif;
                font-size: 1.75rem;
                font-weight: 500;
            }

            .blog-item-infos {
                display: flex;
                padding-top: 1rem;

                div {
                    display: flex;
                    align-items: center;
                    padding-right: 1rem;

                    .icon {
                        font-size: 1rem;
                    }

                    span {
                        font-size: 0.75rem;
                        font-weight: 700;
                        padding-left: 0.5rem;
                    }
                }
            }

            p {
                padding-top: 1rem;
                font-size: 1.813rem;
                font-weight: 300;
                line-height: 2rem;
                min-height: 150px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .rec.rec-arrow {
            width: 40px;
            height: 40px;
            min-width: 40px;
            line-height: 40px;
        }
    }
}
