.bank-info-auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  line-height: 20px;
  margin-bottom: 0;

  .bank-partner-choice:first-child {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .bank-partner-choice:last-child {
    margin-bottom: 10px;
  }

}

.auth-container {
  margin-top: 20px;
}

label {
  margin-right: 0.5rem;
}

