@import '../../../../../styles/colors.scss';

.modal-simulator .release,
.containerL .content .release {
  .rec-carousel-item img {
    width: 100%;
    padding: 0.2em;
    cursor: pointer;
  }

  .rec.rec-arrow {
    background-color: white;
    color: $primary-fgts-color;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    line-height: 40px !important;
  }

  .rec.rec-arrow:hover {
    background-color: $primary-fgts-color;
    color: white;
  }

  .rec.rec-arrow:focus {
    background-color: $primary-fgts-color;
    color: white;
  }

  .rec.rec-arrow:disabled {
    visibility: hidden;
  }

  .rec.rec-dot_active {
    background-color: #ffa03b88;
    box-shadow: 0 0 1px 3px $primary-fgts-color;
  }

  .rec.rec-dot:hover {
    box-shadow: 0 0 1px 3px $primary-fgts-color;
  }

  @media screen and (max-width: 768px) {
    .rec-carousel-item img {
      padding: 0;
    }

    .rec.rec-arrow {
      width: 40px;
      height: 40px;
      min-width: 40px;
      line-height: 40px;
    }
  }
}

.modal-simulator.partner-page-simulator .release {
  .rec.rec-arrow {
    background-color: white;
    color: #000;
  }

  .rec.rec-arrow:hover {
    background-color: #fff;
  }

  .rec.rec-arrow:focus {
    background-color: #fff;
    color: #000;
  }

  .rec.rec-dot_active {
    background-color: #000;
    box-shadow: 0 0 1px 3px #fff;
  }
}
